import React from "react"
import { graphql } from "gatsby"
import { useBreakpoint } from "gatsby-plugin-breakpoints"

import Layout from "../components/layout/layout"
import SEO from "../components/seo"
import Navbar from "../components/navbar/navbar"
import NavbarMobile from "../components/navbar/navbarMobile"
import Footer from "../components/footer/footer"
import FooterMobile from "../components/footer/footerMobile"

import styled from "styled-components"
import font from "../fonts/fonts.module.scss"
import { Button } from "../components/buttons"

import RB from "../fonts/Raleway-Bold.ttf"
import RSB from "../fonts/Raleway-SemiBold.ttf"
import RR from "../fonts/Raleway-Regular.ttf"

import Slider from "react-slick"
import seta from "../images/homepage/seta.svg"
import Img from "gatsby-image"

function NextArrow(props) {
  const { className, style, onClick } = props
  return (
    <Button
      className={className}
      style={{ ...style }}
      onClick={onClick}
      noHover
      regular
    >
      <img src={seta} alt="Seta" />
    </Button>
  )
}
function PrevArrow(props) {
  const { className, style, onClick } = props
  return (
    <Button
      className={className}
      style={{ ...style }}
      onClick={onClick}
      noHover
      regular
    >
      <img src={seta} alt="Seta" />
    </Button>
  )
}

const BlogTemplate = ({ data }) => {
  const settings = {
    dots: false,
    infinite: true,
    speed: 200,
    slidesToShow: 1,
    slidesToScroll: 1,
    swipeToSlide: true,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
  }

  return (
    <Layout>
      <SEO
        title={data.markdownRemark.frontmatter.title}
        description={data.markdownRemark.frontmatter.text}
        date={data.markdownRemark.frontmatter.date}
        path={data.markdownRemark.frontmatter.path}
        img={data.markdownRemark.frontmatter.image.publicURL}
        article={true}
      />
      {useBreakpoint().mobile ? (
        <>
          <NavbarMobile />
        </>
      ) : (
        <>
          <Navbar />
        </>
      )}
      <PostTitle>
        <p className={font.rSB + " data"}>
          {data.markdownRemark.frontmatter.data}
        </p>
        <p className={font.rSB + " title"}>
          {data.markdownRemark.frontmatter.title}
        </p>
        <div className="break" />
      </PostTitle>
      <StyledContent
        dangerouslySetInnerHTML={{ __html: data.markdownRemark.html }}
      />

      {data.markdownRemark.frontmatter.slider && (
        <SliderContent>
          <Slider {...settings}>
            {data.markdownRemark.frontmatter.slider.map((data, i) => (
              <div className="slide" key={"clinicaSlide" + i}>
                <div className="clinica">
                  <Img
                    fluid={data.childImageSharp.fluid}
                    style={{ width: `100%`, height: "auto" }}
                    imgStyle={{ objectFit: "cover" }}
                  />
                </div>
              </div>
            ))}
          </Slider>
        </SliderContent>
      )}

      {useBreakpoint().mobile ? (
        <>
          <FooterMobile data={data.globalJson.footer} />
        </>
      ) : (
        <>
          <Footer data={data.globalJson.footer} />
        </>
      )}
    </Layout>
  )
}

export default BlogTemplate

const PostTitle = styled.div`
  width: 50%;
  @media only screen and (max-width: 800px) {
    width: 65%;
  }
  @media only screen and (max-width: 420px) {
    width: 75%;
  }
  margin: 7vw auto 50px;
  .data {
    font-size: 24px;
    letter-spacing: 0.1em;
    color: #b7a99a;
  }
  .title {
    font-size: 26px;
    letter-spacing: 0.1em;
    color: #231f20;
    margin: 10px 0;
    text-transform: uppercase;
  }
  @media only screen and (max-width: 1100px) {
    .data {
      font-size: calc(16px + 4 * (100vw - 320px) / 780);
    }
    .title {
      font-size: calc(18px + 4 * (100vw - 320px) / 780);
    }
  }
  .break {
    width: 50px;
    height: 4px;
    background-color: #b7a99a;
  }
`

const StyledContent = styled.div`
  display: flex;
  flex-direction: column;
  width: 50%;
  @media only screen and (max-width: 800px) {
    width: 65%;
  }
  @media only screen and (max-width: 420px) {
    width: 75%;
  }
  margin: 0 auto 5vw;

  @font-face {
    font-family: "Raleway Bold";
    src: url(${RB}) format("truetype");
  }
  @font-face {
    font-family: "Raleway Semi-Bold";
    src: url(${RSB}) format("truetype");
  }
  @font-face {
    font-family: "Raleway Regular";
    src: url(${RR}) format("truetype");
  }
  h1 {
    font-family: "Raleway Bold", sans-serif;
    text-transform: uppercase;
    font-size: calc(20px + 20 * (100vw - 769px) / 1151);
    @media only screen and (min-width: 1920px) {
      font-size: 40px;
    }
    letter-spacing: 0.1em;
    text-align: center;
    color: #231f20;
    margin: 3rem 0;
  }
  h2 {
    font-family: "Raleway Semi-Bold", sans-serif;
    color: #b7a99a;
    margin: 1rem 0;
    text-align: center;
  }
  p,
  ol,
  ul {
    font-family: "Raleway Regular", sans-serif;
    line-height: 1.5em;
    font-size: calc(14px + 8 * (100vw - 769px) / 1151);
    @media only screen and (min-width: 1920px) {
      font-size: 22px;
    }
    letter-spacing: 0.1em;
    color: #808080;
    margin: 1rem 0;
    text-align: justify;
    text-justify: inter-word;
    li {
      margin: 1em 0;
    }
  }
  iframe {
    margin: auto;
  }

  .sixteenByNine {
    position: relative;
    padding-bottom: 56.25%;
  }
  .sixteenByNine iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
`

const SliderContent = styled.div`
  position: relative;
  width: 50%;
  @media only screen and (max-width: 800px) {
    width: 65%;
  }
  @media only screen and (max-width: 420px) {
    width: 75%;
  }
  margin: 0 auto 5vw;
  .slick-track {
    display: flex;
    align-items: center;
  }
  .slick-list {
    overflow: hidden;
  }
  .slideTitle {
    color: #231f20;
    font-size: 20px;
    text-align: center;
    letter-spacing: 4px;
    line-height: 24px;
    text-transform: uppercase;
    z-index: 2;
    width: 100%;
    padding: 10px;
    text-align: center;
  }
  .slide {
    height: 100%;
    width: 100%;
    .clinica {
      width: 100%;
      position: relative;
    }
  }
  .slick-prev {
    width: calc(36px + 24 * (100vw - 769px) / 1151);
    height: calc(36px + 24 * (100vw - 769px) / 1151);
    @media only screen and (min-width: 1920px) {
      width: 60px;
      height: 60px;
    }
    background-color: #b7a99a;
    color: #ffffff;
    z-index: 1;

    -webkit-touch-callout: none;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    user-select: none;
    cursor: pointer;
    position: absolute;
    bottom: 50%;
    left: calc(-70px + -80 * (100vw - 769px) / 1151);
    transform: translate(-50%, 50%);
    img {
      position: absolute;
      width: 0.6em;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%) rotate(180deg);
    }
  }
  .slick-next {
    width: calc(36px + 24 * (100vw - 769px) / 1151);
    height: calc(36px + 24 * (100vw - 769px) / 1151);
    @media only screen and (min-width: 1920px) {
      width: 60px;
      height: 60px;
    }
    background-color: #b7a99a;
    color: #ffffff;
    z-index: 1;

    -webkit-touch-callout: none;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    user-select: none;
    cursor: pointer;
    position: absolute;
    bottom: 50%;
    right: calc(-70px + -80 * (100vw - 769px) / 1151);
    transform: translate(50%, 50%);
    img {
      position: absolute;
      width: 0.6em;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }
`

export const Json = graphql`
  query blogTemplate($slug: String!) {
    globalJson {
      footer {
        newsletter {
          subscreva
          btnTxt
        }
        aveiro {
          title
          morada
          contacto
        }
        agueda {
          title
          morada
          contacto
        }
        porto {
          title
          morada
          contacto
        }
        viseu {
          title
          morada
          contacto
        }
        oliveira {
          title
          morada
          contacto
        }
        email
        schedule
        bg {
          publicURL
        }
      }
    }
    markdownRemark(frontmatter: { path: { eq: $slug } }) {
      frontmatter {
        title
        text
        date(formatString: "DD-MM-YYYY")
        data
        path
        image {
          publicURL
        }
        slider {
          childImageSharp {
            fluid(quality: 70, maxWidth: 1600) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
      html
    }
  }
`
